import React from "react"

const BackButton = React.forwardRef(({ children, ...props }, ref) => {
  const onClick = e => {
    e.preventDefault()
    history.back()
  }
  return (
    <a {...props} ref={ref} href="#" onClick={onClick}>
      <svg
        width="15"
        height="15"
        version="1.1"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className="mr-2"
        style={{ verticalAlign: "middle", marginTop: -3 }}
      >
        <path d="m75.438 90.875-40.875-40.875 40.875-40.875c1.8281-1.8477 1.8164-4.8281-0.03125-6.6562s-4.8281-1.8164-6.6562 0.03125l-44.188 44.188c-1.8281 1.832-1.8281 4.793 0 6.625l44.188 44.188c1.8477 1.7227 4.7266 1.6719 6.5117-0.11328 1.7852-1.7852 1.8359-4.6641 0.11328-6.5117z" />
      </svg>
      {children}
    </a>
  )
})

export default BackButton
