import React from "react"

const formatProductTitle = (title, longevity) => {
  return title.split(", ").map((t, i) => (
    <>
      {i === 1 && (
        <>
          <span className={`product-npk`}>{t} </span>
        </>
      )}
      {i === 2 && (
          <span className={`product-taginfo small`}>{t} </span>
      )}

      {i !== 1 && i !== 2 && <span>{t}</span>}
    </>
  ))
}

export default formatProductTitle
