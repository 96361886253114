import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import SEO from "../components/SEO"
import Layout from "../layouts/default"
import BackButton from "../components/BackButton"
import QuoteForm from "../components/QuoteForm"
import formatProductTitle from "../helpers/formatProductTitle"
import { Modal, Collapse } from "react-bootstrap"

const ProductTemplate = props => {
  const { product } = props.data.wp
  const { location } = props
  const ratesRef = useRef(null)

  const [state, setState] = useState({
    showQuoteForm: false,
    showRates: false,
    nValue: product.productFields.nValue,
    maxSolubilityLbGal: product.productFields.maxSolubility,
    maxSolubilityOz: (product.productFields.maxSolubility * 16).toFixed(1),
    maxSolubilityBagGal: (25 / product.productFields.maxSolubility).toFixed(1),
    maxSolubilityGr: ((product.productFields.maxSolubility * 16 * 28.3495)/3.78541).toFixed(1),
    ecPer100PpmN: product.productFields.ec,
    table1Rates: [
      {
        fertConc: 25,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 50,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 75,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 100,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 125,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 150,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 175,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 200,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 250,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 300,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 350,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 400,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 450,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 500,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 600,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
    ],
    table2Rates: [
      {
        fertConc: 25,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 50,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 75,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 100,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 125,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 150,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 175,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 200,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 250,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 300,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 350,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 400,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 450,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 500,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 600,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
    ],
    table3Rates: [
      {
        fertConc: 25,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 50,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 75,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 100,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 125,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 150,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 175,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 200,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 250,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 300,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 350,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 400,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 450,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 500,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 600,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
    ],
    table4Rates: [
      {
        fertConc: 25,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 50,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 75,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 100,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 125,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 150,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 175,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 200,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 250,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 300,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 350,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 400,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 450,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 500,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 600,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
    ],
    table5Rates: [
      {
        fertConc: 25,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 50,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 75,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 100,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 125,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 150,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 175,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 200,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 250,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 300,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 350,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 400,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 450,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 500,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 600,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
    ],
    table6Rates: [
      {
        fertConc: 25,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 50,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 75,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 100,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 125,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 150,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 175,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 200,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 250,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 300,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 350,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 400,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 450,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 500,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
      {
        fertConc: 600,
        ratio15: "",
        ratio100: "",
        ratio128: "",
        ratio200: "",
        ratio300: "",
        ec: "",
      },
    ],
  })

  //const [maxSolubilityOz, setMaxSolubilityOz] = useState(null)
  //const [maxSolubilityBagGal, setMaxSolubilityBagGal] = useState(null)
  //const [maxSolubilityGr, setMaxSolubilityGr] = useState(null)

  // TODO: 
  //  - When the component mounts, update the state with the product's rates 
  //    (grab the custom field from the "props.data.wp.product" item - update the graphQL query at the bottom!)
  //  - Then calculate the rates
  //  - If the user is coming from the Find Rates page, show the rate tables immediately (otherwise hide and toggle them on button click)
  
  useEffect(() => {
    // Component mounted
    calculateRates()
    location?.state?.showRates && toggleRates()
  }, [])

  const calculateRates = () => {
    // Prevent default form behavior
    // e.preventDefault()

    // Clone the current state and make calculations to the new object
    var obj = state

    // Make changes to the object
    var aux = (100/(obj.nValue/100))*100*3.785/1000/28.349527
    obj.table1Rates.map(item => {
      item.ratio15 = (aux * (item.fertConc/100) * 0.15).toFixed(1)
      item.ratio100 = (aux * (item.fertConc/100)).toFixed(1)
      item.ratio128 = (aux * (item.fertConc/100) * 1.28).toFixed(1)
      item.ratio200 = (aux * (item.fertConc/100) * 2).toFixed(1)
      item.ratio300 = (aux * (item.fertConc/100) * 3).toFixed(1)
      item.ec = (obj.ecPer100PpmN * (item.fertConc/100)).toFixed(2)
	  return item
    })
    
    var aux = (100/(obj.nValue/100))*100*3.785/1000/28.349527
    obj.table2Rates.map(item => {
      item.ratio15 = (aux * (item.fertConc/100) * 0.15 < obj.maxSolubilityOz) ? (aux * (item.fertConc/100) * 0.15).toFixed(1) : '***'
      item.ratio100 = (aux * (item.fertConc/100) < obj.maxSolubilityOz) ? (aux * (item.fertConc/100)).toFixed(1) : '***'
      item.ratio128 = (aux * (item.fertConc/100) * 1.28 < obj.maxSolubilityOz) ? (aux * (item.fertConc/100) * 1.28).toFixed(1) : '***'
      item.ratio200 = (aux * (item.fertConc/100) * 2 < obj.maxSolubilityOz) ? (aux * (item.fertConc/100) * 2).toFixed(1) : '***'
      item.ratio300 = (aux * (item.fertConc/100) * 3 < obj.maxSolubilityOz) ? (aux * (item.fertConc/100) * 3).toFixed(1) : '***'
      item.ec = (obj.ecPer100PpmN * (item.fertConc/100)).toFixed(2)
	  return item
    })
    
    var aux = (100/(obj.nValue/100))*100*3.785/1000/28.349527*7.489
    obj.table3Rates.map(item => {
      item.ratio15 = (aux * (item.fertConc/100) * 0.15 < obj.maxSolubilityGr) ? (aux * (item.fertConc/100) * 0.15).toFixed(1) : '***'
      item.ratio100 = (aux * (item.fertConc/100) < obj.maxSolubilityGr) ? (aux * (item.fertConc/100)).toFixed(1) : '***'
      item.ratio128 = (aux * (item.fertConc/100) * 1.28 < obj.maxSolubilityGr) ? (aux * (item.fertConc/100) * 1.28).toFixed(1) : '***'
      item.ratio200 = (aux * (item.fertConc/100) * 2 < obj.maxSolubilityGr) ? (aux * (item.fertConc/100) * 2).toFixed(1) : '***'
      item.ratio300 = (aux * (item.fertConc/100) * 3 < obj.maxSolubilityGr) ? (aux * (item.fertConc/100) * 3).toFixed(1) : '***'
      item.ec = (obj.ecPer100PpmN * (item.fertConc/100)).toFixed(2)
	  return item
    })
    
    var aux = (100/(obj.nValue/100))*100*3.785/1000/28.349527*7.489
    obj.table4Rates.map(item => {
      item.ratio15 = (aux * (item.fertConc/100) * 0.15).toFixed(1)
      item.ratio100 = (aux * (item.fertConc/100)).toFixed(1)
      item.ratio128 = (aux * (item.fertConc/100) * 1.28).toFixed(1)
      item.ratio200 = (aux * (item.fertConc/100) * 2).toFixed(1)
      item.ratio300 = (aux * (item.fertConc/100) * 3).toFixed(1)
      item.ec = (obj.ecPer100PpmN * (item.fertConc/100)).toFixed(2)
	  return item
    })
    
    var aux = (25*16)/((100/(obj.nValue/100))*100*3.785/1000/28.349527)
    obj.table5Rates.map(item => {
      item.ratio15 = (((aux / (item.fertConc/100)) / 0.15) > obj.maxSolubilityBagGal) ? ((aux / (item.fertConc/100)) / 0.15).toFixed(1) : '***'
      item.ratio100 = (((aux / (item.fertConc/100))) > obj.maxSolubilityBagGal) ? ((aux / (item.fertConc/100))).toFixed(1) : '***'
      item.ratio128 = (((aux / (item.fertConc/100)) / 1.28) > obj.maxSolubilityBagGal) ? ((aux / (item.fertConc/100)) / 1.28).toFixed(1) : '***'      
      item.ratio200 = (((aux / (item.fertConc/100)) / 2) > obj.maxSolubilityBagGal) ? ((aux / (item.fertConc/100)) / 2).toFixed(1) : '***'
      item.ratio300 = (((aux / (item.fertConc/100)) / 3) > obj.maxSolubilityBagGal) ? ((aux / (item.fertConc/100)) / 3).toFixed(1) : '***'
      item.ec = (obj.ecPer100PpmN * (item.fertConc/100)).toFixed(2)
	  return item
    })
    
    var aux = (25*16)/((100/(obj.nValue/100))*100*3.785/1000/28.349527)
    obj.table6Rates.map(item => {
      item.ratio15 = ((aux / (item.fertConc/100)) / 0.15).toFixed(1)
      item.ratio100 = (aux / (item.fertConc/100)).toFixed(1)
      item.ratio128 = ((aux / (item.fertConc/100)) / 1.28).toFixed(1)
      item.ratio200 = ((aux / (item.fertConc/100)) / 2).toFixed(1)
      item.ratio300 = ((aux / (item.fertConc/100)) / 3).toFixed(1)
      item.ec = (obj.ecPer100PpmN * (item.fertConc/100)).toFixed(2)
	  return item
    })

    // Then set the state to the new object
    setState({...state, obj})
  }

  const productUrl =
    props?.data?.site?.siteMetadata?.siteURL + props?.location?.pathname || null

  // const longevityRanges = product.longevityRanges.nodes.map(
  //   range => range.slug
  // )

  const formattedTitle = formatProductTitle(product.title)

  const toggleQuoteForm = () => {
    setState({
      ...state,
      showQuoteForm: !state.showQuoteForm,
    })
  }
  const toggleRates = () => {
    setState({
      ...state,
      showRates: !state.showRates,
    })
  }

  const scrollToRates = () => {
    let element = ratesRef.current;
    let headerOffset = 66;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;
    
    window.scrollTo({
        top: window.pageYOffset + offsetPosition,
        behavior: "smooth"
    });   
  }

  return (
    <Layout
      pageTitle={product.title}
      slug={product.slug}
      location={props.location}
      noHeaderImage={true}
    >
      <SEO title={product.title} />

      <div className="container mt-5 pt-3 pt-md-5">
        <BackButton className="btn btn-link mb-2">Back</BackButton>

        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <Img
              fluid={{
                ...product.featuredImage.node.imageFile.childImageSharp.fluid,
                aspectRatio: 1,
                objectFit: "contain",
              }}
              objectFit="contain"
              objectPosition="50% 50%"
              loading="eager"
            />
          </div>
          <div className="col-12 col-lg-6">
            <header className="product-header">
              <div className="row align-items-end">
                <div className="col">
                  <h1 className="product-title h2">{formattedTitle}</h1>
                </div>
                <div className="col-auto text-center">
                  <p className="small mb-1 text-muted">Share</p>
                  <ul className="list-inline share-links">
                    <li className="list-inline-item">
                      <a
                        href={`sms:%20&body=Check out ${encodeURI(
                          product.title
                        )}: ${encodeURI(productUrl)}`}
                        className="d-block pl-2"
                        title="Message"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          viewBox="0 0 30 30"
                        >
                          <path d="M7.4 25h.3l.3.3c2.1 1.4 4.5 2.2 7 2.2 6.9 0 12.5-5.6 12.5-12.5S22 2.4 15.1 2.4 2.5 8 2.5 14.9c0 2.5.8 5 2.2 7.1l.3.4v.3l-1.2 3.5L7.4 25zM.1 14.9c0-4 1.6-7.7 4.4-10.6C7.3 1.6 11.1 0 15.1 0s7.7 1.6 10.6 4.4c2.8 2.8 4.4 6.6 4.4 10.6s-1.6 7.7-4.4 10.6c-2.8 2.8-6.6 4.4-10.6 4.4-2.8 0-5.5-.8-7.9-2.3L0 30l2.4-7.2C.9 20.5.1 17.8.1 14.9z" />
                        </svg>
                        <span className="sr-only">Message</span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={`mailto:?subject=${encodeURI(
                          product.title
                        )}&body=Check out ${encodeURI(
                          product.title
                        )}: ${encodeURI(productUrl)}`}
                        className="d-block pl-2"
                        title="Email"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          viewBox="0 0 30 30"
                        >
                          <path d="M27.5 6.5h-25v2.8L15 15.5l12.5-6.4V6.5zm0 5.4L15 18.3 2.5 12.1v11.4h24.9l.1-11.6zm2.5-8V26H0V3.9h30z" />
                        </svg>
                        <span className="sr-only">Email</span>
                      </a>
                    </li>

                    {product.productFields.productInformationPdf?.mediaItemUrl && (
                      <li className="list-inline-item">
                        <a
                          href={product.productFields.productInformationPdf.mediaItemUrl}
                          target="_blank"
                          className="d-block pl-2"
                          title="Print"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                          </svg>
                          <span className="sr-only">Download/Print Product Information Sheet</span>
                        </a>
                      </li>
                    )}


                  </ul>
                </div>
              </div>
            </header>

            {/* Main Content */}
            {product.content && (
              <div
                className="product-description"
                dangerouslySetInnerHTML={{ __html: product.content }}
              />
            )}
            <div className="row">
              {product.productFields.productInformationPdf?.mediaItemUrl && (
                <div className="col-12">
                  <a
                    href={product.productFields.productInformationPdf.mediaItemUrl}
                    target="_blank"
                    className="text-primary font-weight-bold my-3 d-inline-block"
                  >
                    <span>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z" clipRule="evenodd" />
                      </svg>&nbsp; */}
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>&nbsp;
                      Download Product Information Sheet</span>
                  </a>
                </div>
              )}
              
              <div className="col-6">
                <button
                  onClick={toggleQuoteForm}
                  type="button"
                  aria-label="Toggle quote form"
                  className="btn btn-icl btn-block btn-lg btn-skew my-3 d-none d-md-block"
                >
                  <span>Request a Quote</span>
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={toggleRates}
                  type="button"
                  aria-label="Toggle product rates"
                  className="btn btn-outline-icl btn-skew btn-block btn-lg my-3 d-none d-md-block"
                >
                  <span>Product Rates</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={toggleQuoteForm}
          type="button"
          aria-label="Toggle quote form"
          className="btn btn-icl btn-skew btn-block btn-lg my-3 d-md-none"
        >
          <span>Request a Quote</span>
        </button>

        <button
          onClick={toggleRates}
          type="button"
          aria-label="Toggle product rates"
          className="btn btn-outline-icl btn-skew btn-block btn-lg my-3 d-md-none"
          aria-controls="rates"
          aria-expanded={state.showRates}
        >
          <span>Product Rates</span>
        </button>

        <Collapse in={state.showRates} onEntered={scrollToRates}>
          <div id="rates" ref={ratesRef}>
          <div className="py-3 py-md-5">
            <h2 className="mb-3 font-weight-normal text-icl">Rates for {product.title}</h2>
            <h3>Weight (in ounces) of product needed to mix one gallon of concentrate</h3>
            <div className="table-responsive-wrapper">
              <div className="table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th rowSpan="2">Target Fertilizer Concentration (N/ppm) After Dilution</th>
                      <th colSpan="5">Common Injector Ratios</th>
                      <th rowSpan="2">EC (mmhos/cm) of Target Feed Rate After Dilution</th>
                    </tr>
                    <tr>
                      <th>1:15</th>
                      <th>1:100</th>
                      <th>1:128</th>
                      <th>1:200</th>
                      <th>1:300</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.table2Rates.map((item, i) => {
                      return (
                        <tr key={`table2-${i}`}>
                          <td>{item.fertConc}</td>
                          <td>{item.ratio15}</td>
                          <td>{item.ratio100}</td>
                          <td>{item.ratio128}</td>
                          <td>{item.ratio200}</td>
                          <td>{item.ratio300}</td>
                          <td>{item.ec}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="table-fade"></div>
                <p className="small">*** Exceeds maximum solubility.</p>
              </div>
            </div>
            <h3>Gallons of water required to dissolve one 25lb. bag of fertilizer</h3>
            <div className="table-responsive-wrapper">
              <div className="table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th rowSpan="2">Target Fertilizer Concentration (N/ppm) After Dilution</th>
                      <th colSpan="5">Common Injector Ratios</th>
                      <th rowSpan="2">EC (mmhos/cm) of Target Feed Rate After Dilution</th>
                    </tr>
                      <tr>
                      <th>1:15</th>
                      <th>1:100</th>
                      <th>1:128</th>
                      <th>1:200</th>
                      <th>1:300</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.table5Rates.map((item, i) => {
                      return (
                        <tr key={`table5-${i}`}>
                          <td>{item.fertConc}</td>
                          <td>{item.ratio15}</td>
                          <td>{item.ratio100}</td>
                          <td>{item.ratio128}</td>
                          <td>{item.ratio200}</td>
                          <td>{item.ratio300}</td>
                          <td>{item.ec}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="table-fade"></div>
                <p className="small">*** Exceeds maximum solubility.</p>
              </div>
            </div>




            {product.productFields.productInformationPdf?.mediaItemUrl && (
                <div>
                  <a
                    href={product.productFields.productInformationPdf.mediaItemUrl}
                    target="_blank"
                    className="text-primary font-weight-bold py-3 d-inline-block"
                  >
                    <span>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z" clipRule="evenodd" />
                      </svg>&nbsp; */}
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>&nbsp;
                      Download Product Information Sheet</span>
                  </a>
                </div>
              )}
          </div>
          </div>
        </Collapse>

        <Modal
          show={state.showQuoteForm}
          onHide={toggleQuoteForm}
          size="lg"
          aria-labelledby="quote-form-title"
          className="quote-form-overlay"
        >
          <Modal.Header closeButton>
            <div className="modal-title h2" id="quote-form-title">
              Request a Quote
            </div>
          </Modal.Header>
          <div className="modal-custom-content p-3">
            <QuoteForm
              productsToShow={
                location?.state?.productsToShow
                  ? location.state.productsToShow
                  : "all"
              }
              activeProduct={product}
            />
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  query($id: ID!) {
    wp {
      product(id: $id, idType: DATABASE_ID) {
        databaseId
        title
        content
        slug
        featuredImage {
          node {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        productFields {
          nValue
          ec
          maxSolubility
          productInformationPdf {
            mediaItemUrl
          }
        }
      }
    }

    site {
      id
      siteMetadata {
        title
        siteURL
      }
    }
  }
`
